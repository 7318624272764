#BuylistCards td, #BuylistCards th {
    text-align: center;
}

#CardSearch input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#CardSearch ul li:hover {
    background: rgba(150, 150, 150, 0.4);
}

#CardSearch ul li:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#BuylistCards .HoverImage {
    display: none;
}

#BuylistCards tr:hover .HoverImage {
    position: absolute;
    display: inherit;
    width: 150px;
    left: -150px;
}
