.CTable {
    border-collapse: collapse;
}

.CTable a {
    color: inherit;
    text-decoration: inherit;
}

.CTHeader {
    font-variant: small-caps;
    font-weight: bold;
    border-bottom-width: 3px;
}

.CTable .CTRow:hover {
    background-color: rgba(53, 57, 66, 0.1);
}