@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* ----- PlusMinus ----- */

.PlusMinusInputGroup .plus, .PlusMinusInputGroup .minus{
    padding: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.PlusMinusInputGroup .plus {
    color: rgb(174, 213, 129);
    background: rgba(174, 213, 129, 0.3);
}

.PlusMinusInputGroup .minus {
    color: rgb(255, 138, 101);
    background: rgba(255, 138, 101, 0.3);
}

.PlusMinusInputGroup .amount {
    max-width: 40px;
}