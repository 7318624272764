@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css?family=Tajawal");
@import url("//cdn.jsdelivr.net/npm/keyrune@latest/css/keyrune.css");

body {
    margin: 0;
    padding: 0;
    font-family: 'Tajawal', sans-serif;
    background-color: rgba(207,193,114,0.05);
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

h1 {font-size: 30px}
h2 {font-size: 26px}
h3 {font-size: 22px}
h4 {font-size: 18px}
h5 {font-size: 16px}

.pointer {
    cursor: pointer;
}
