.MultiList .Input{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
}

.MultiList .Search {
    position: relative;
    height: 100%;
}
