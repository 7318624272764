@import url(https://fonts.googleapis.com/css?family=Tajawal);
@import url(//cdn.jsdelivr.net/npm/keyrune@latest/css/keyrune.css);
.Card {
    position: relative;
    background-size: cover;
    background-position: center center;
}

.Card .banner {
    display: none !important;
    position: absolute;
    height: auto;
    top: 15%;
}

.Card:hover .banner {
    display: flex !important;
}

.Card table {
    border-collapse: separate;
}

.Card table img {
    opacity: 0.7;
}

.Card table tr {
    background: rgba(255, 255, 255, 0.7);
}

.Card table tr:last-child {
    border-bottom: 0;
}

.Card table td {
    width: 25%;
    padding: 0;
    text-align: center;
    font-weight: bold;
}

.Card .total {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    border-radius: 8px 0 8px 0;
    background: rgb(255, 138, 101);
    border: 1px solid #5a6572;
}

.Card .total.playset {
    background: rgb(174, 213, 129);
}

.Card .plus {
    background: rgba(174, 213, 129, 0.7);
}

.Card .plus:hover {
    background: rgba(197, 225, 165, 0.5);
}

.Card .minus {
    background: rgba(255, 138, 101, 0.7);
}

.Card .minus:hover {
    background: rgba(255, 171, 145, 0.5);
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* ----- PlusMinus ----- */

.PlusMinusInputGroup .plus, .PlusMinusInputGroup .minus{
    padding: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.PlusMinusInputGroup .plus {
    color: rgb(174, 213, 129);
    background: rgba(174, 213, 129, 0.3);
}

.PlusMinusInputGroup .minus {
    color: rgb(255, 138, 101);
    background: rgba(255, 138, 101, 0.3);
}

.PlusMinusInputGroup .amount {
    max-width: 40px;
}
.MultiList .Input{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
}

.MultiList .Search {
    position: relative;
    height: 100%;
}

/*
$darkGrey: #20232a;
$lightGrey: #2B2F38;
$blue: #61dafb;
 */

#Nav {
    background: #20232a;
    color: #61dafb;
    position: fixed;
    width: 200px;
    height: 100%;
    float: left;
    z-index: 3;
}

#Nav.closed {
    width: 50px;
}

#Nav.closed #NavSearch, #Nav.closed .NavEntry span {
    display: none;
}

#Nav .NavEntry:hover {
    background: #2B2F38;
}

#Nav .NavEntry a {
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    color: inherit;
}

#Nav .NavEntry i {
    width: 20px;
}
#BuylistCards td, #BuylistCards th {
    text-align: center;
}

#CardSearch input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#CardSearch ul li:hover {
    background: rgba(150, 150, 150, 0.4);
}

#CardSearch ul li:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#BuylistCards .HoverImage {
    display: none;
}

#BuylistCards tr:hover .HoverImage {
    position: absolute;
    display: inherit;
    width: 150px;
    left: -150px;
}

.CTable {
    border-collapse: collapse;
}

.CTable a {
    color: inherit;
    text-decoration: inherit;
}

.CTHeader {
    font-feature-settings: "c2sc";
    font-variant: small-caps;
    font-weight: bold;
    border-bottom-width: 3px;
}

.CTable .CTRow:hover {
    background-color: rgba(53, 57, 66, 0.1);
}
.DeckCard:hover {
    z-index: 100;
    background-color: #61dafb;
    box-shadow: 0px 0px 5px 5px #61dafb;
    border-radius: 5px;
}

.DeckCard.NotEnough {
    background-color: #e57373;
    box-shadow: 0px 0px 5px 5px #e57373;
}
/*
$darkGrey: #20232a;
$lightGrey: #2B2F38;
$blue: #61dafb;
 */

section {
    position: relative;
    padding-top: 20px;
    margin-left: 40px !important;
}

a {
    color: #61dafb;
}


body {
    margin: 0;
    padding: 0;
    font-family: 'Tajawal', sans-serif;
    background-color: rgba(207,193,114,0.05);
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

h1 {font-size: 30px}
h2 {font-size: 26px}
h3 {font-size: 22px}
h4 {font-size: 18px}
h5 {font-size: 16px}

.pointer {
    cursor: pointer;
}

