/*
$darkGrey: #20232a;
$lightGrey: #2B2F38;
$blue: #61dafb;
 */

#Nav {
    background: #20232a;
    color: #61dafb;
    position: fixed;
    width: 200px;
    height: 100%;
    float: left;
    z-index: 3;
}

#Nav.closed {
    width: 50px;
}

#Nav.closed #NavSearch, #Nav.closed .NavEntry span {
    display: none;
}

#Nav .NavEntry:hover {
    background: #2B2F38;
}

#Nav .NavEntry a {
    font-variant: all-small-caps;
    color: inherit;
}

#Nav .NavEntry i {
    width: 20px;
}