.Card {
    position: relative;
    background-size: cover;
    background-position: center center;
}

.Card .banner {
    display: none !important;
    position: absolute;
    height: auto;
    top: 15%;
}

.Card:hover .banner {
    display: flex !important;
}

.Card table {
    border-collapse: separate;
}

.Card table img {
    opacity: 0.7;
}

.Card table tr {
    background: rgba(255, 255, 255, 0.7);
}

.Card table tr:last-child {
    border-bottom: 0;
}

.Card table td {
    width: 25%;
    padding: 0;
    text-align: center;
    font-weight: bold;
}

.Card .total {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    border-radius: 8px 0 8px 0;
    background: rgb(255, 138, 101);
    border: 1px solid #5a6572;
}

.Card .total.playset {
    background: rgb(174, 213, 129);
}

.Card .plus {
    background: rgba(174, 213, 129, 0.7);
}

.Card .plus:hover {
    background: rgba(197, 225, 165, 0.5);
}

.Card .minus {
    background: rgba(255, 138, 101, 0.7);
}

.Card .minus:hover {
    background: rgba(255, 171, 145, 0.5);
}