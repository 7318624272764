.DeckCard:hover {
    z-index: 100;
    background-color: #61dafb;
    box-shadow: 0px 0px 5px 5px #61dafb;
    border-radius: 5px;
}

.DeckCard.NotEnough {
    background-color: #e57373;
    box-shadow: 0px 0px 5px 5px #e57373;
}